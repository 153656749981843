<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <fragment>
    <v-row>
      <v-col cols="12">
        <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.edit')}`">
          <v-card-text v-if="isLoading">
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card-text>

          <template v-if="!isLoading">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    color="error"
                    class="mb-4 me-3"
                    @click="$router.push({ name: 'hotels-contrate-list' })"
                  >
                    <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                    <span>{{ $t('btn.back') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    :loading="loading"
                    :disabled="verifyContrate"
                    @click="save()"
                  >
                    <span>{{ btnTitle }}</span>
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                  </v-btn>
                </v-col>

                <v-col
                  v-if="permisos.includes('contrate_hotels:edit')"
                  cols="12"
                  md="1"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="error"
                    class="mb-4 me-3"
                    outlined
                    :disabled="cantTarifarios === 0"
                    @click="deleteTarifarios"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    <span class="pl-2">Tarifarios</span>
                  </v-btn>
                </v-col>

                <v-spacer></v-spacer>

                <v-col
                  v-if="
                    (
                      model.source.includes('hotetec') ||
                      model.source.includes('dingus') &&
                        permisos.includes('contrate_hotels:edit')
                    )"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="addTarifa"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.tarifa') }}</span>
                  </v-btn>
                </v-col>

                <!--<v-col
                  v-if="model.source.includes('hotetec') &&
                    permisos.includes('contrate_hotels:edit')
                  "
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="addTarifa"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.ocupations') }}</span>
                  </v-btn>
                </v-col>-->

                <v-col
                  v-if="
                    (
                      !model.source.includes('hotetec') &&
                      !model.source.includes('dingus') &&
                      permisos.includes('contrate_hotels:edit')
                    )"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="addTarifaDirect"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.tarifa') }}</span>
                  </v-btn>
                </v-col>

                <v-col
                  v-if="model.source.includes('hotetec')"
                  cols="12"
                  md="2"
                  class="mr-10"
                >
                  <v-btn
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="uploadLote"
                  >
                    <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
                    <span class="pl-2">{{ $t('btn.importFiles') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="model.source.includes('dingus')"
                  cols="12"
                  md="2"
                  class="mr-10"
                >
                  <v-btn
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="uploadLoteDingus"
                  >
                    <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
                    <span class="pl-2">{{ $t('btn.importFiles') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="model.identificador"
                    :label="$t('lbl.identificador')"
                    class="pt-2"
                    outlined
                    dense
                    hide-details
                    :disabled="$store.state.app.onlyShow"
                  ></v-text-field>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="2"
                >
                  <v-switch
                    v-model="model.stopSale"
                    :label="$t('lbl.stopSale')"
                    hide-details
                    class="mt-2"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>-->
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-if="!isLoadingCurrencies"
                    v-model="model.currency_id"
                    :items="currencies"
                    :label="$t('register.moneda')"
                    outlined
                    dense
                    item-text="code"
                    item-value="id"
                    class="pt-2"
                  ></v-select>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="card-heading"
                  ></v-skeleton-loader>
                  <template v-if="model.source.includes('dingus') || model.source.includes('hotetec')">
                    <span v-if="model.price_neto">
                      <b>Neto</b>
                    </span>
                    <span v-else>
                      <b>Comisión:</b> {{ model.pc_price_gross | number('0.00') }} %
                    </span>
                  </template>
                </v-col>
                <!--v-if="sourcesAvalibled.length > 0"-->
                <v-col
                  v-if="model.source.length > 0"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    label="Fuente"
                    :value="model.source.includes('dingus') ? 'Dingus' : (model.source.includes('hotetec') ? 'Hotetec' : 'Directo')"
                    class="pt-2"
                    outlined
                    dense
                    hide-details
                    disabled
                  ></v-text-field>
                  <v-switch
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    v-model="model.notificar_acceso_data"
                    label="Notificar acceso a los hoteles"
                    hide-details
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                  <v-switch
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    v-model="model.notificar_acceso_disp"
                    label="Notificar envío de disponibilidad"
                    hide-details
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                  <!--<v-select
                    v-model="model.source"
                    :items="sourcesAvalibled"
                    label="Fuente"
                    outlined
                    dense
                    item-text="name"
                    item-value="slug"
                    multiple
                    class="pt-2"
                    hide-details="auto"
                    :hint="
                      model.source.includes('hotetec')
                        ? `Para la integración correcta con ${nameHotetec} tiene que llenar los 2 primeros acordeones de cada hotel.`
                        : model.source.includes('dingus')
                          ? `Para la integración correcta con ${nameDingus} tiene que llenar los 2 primeros acordeones de cada hotel.`
                          : ''
                    "
                    :persistent-hint="model.source.includes('hotetec') || model.source.includes('dingus')"
                  ></v-select>-->
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  offset-md="2"
                  class="text-right mt-0 pt-0"
                >
                  <p class="pt-2">
                    <b>Ventas para individuales:</b>
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="mt-0 pt-0"
                >
                  <v-text-field
                    v-model="model.rooms_max"
                    label="Room máx"
                    outlined
                    dense
                    hide-details
                    @keypress="checkPax($event, model.rooms_max)"
                    @input="val => (model.rooms_max = model.rooms_max.toUpperCase())"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="mt-0 pt-0"
                >
                  <v-text-field
                    v-model="model.paxs_max"
                    label="Pax máx"
                    outlined
                    dense
                    hide-details
                    @keypress="checkPax($event, model.paxs_max)"
                    @input="val => (model.paxs_max = model.paxs_max.toUpperCase())"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-switch
                    v-model="model.cancelado"
                    :label="$t('lbl.canceled')"
                    hide-details
                    class="mt-0"
                    :disabled="$store.state.app.onlyShow"
                    @click="setCanceled"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="editObservations"
                    :label="$t('lbl.observations')"
                    hide-details
                    class="mt-2 mb-2"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>
                <v-col
                  v-if="editObservations"
                  cols="12"
                  md="4"
                >
                  <v-tooltip
                    top
                    class="text-right"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="addObservationContrateHotel()"
                      >
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  v-if="editObservations"
                  cols="12"
                  md="12"
                >
                  <Observation
                    v-for="(observation, indO) in observationsHotel"
                    :key="indO"
                    :observation="observation"
                    :index="indO"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </app-card-code>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingCountries"
            v-model="search.country"
            :items="countries"
            :label="$t('lbl.country')"
            outlined
            dense
            hide-details
            @change="changeCountry"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.country') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingStates"
            v-model="search.state"
            :items="states"
            :label="$t('lbl.provinceEstado')"
            outlined
            dense
            hide-details
            @change="changeState"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.provinceEstado') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingCities"
            v-model="search.city"
            :items="cities"
            :label="$t('lbl.locality')"
            outlined
            dense
            hide-details
            @change="changeCity"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.locality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingOperadors"
            v-model="search.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setOperadores"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingMarcas"
            v-model="search.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setMarca"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingHotels"
            v-model="search.hotel_id"
            :items="itemsHotel"
            :search-input.sync="searchHotel"
            hide-details
            hide-selected
            :label="$t('menu.hotels')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <!--v-if="model.source.includes('hotetec') || model.source.includes('dingus')"-->
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-if="!isLoadingSources"
            v-model="search.code"
            :items="codesHotel"
            :search-input.sync="searchCodesHotel"
            hide-details
            hide-selected
            label="Code"
            outlined
            dense
            item-text="code"
            @change="setCodesHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Code
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.code"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.code }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mb-4"></v-divider>
    <template v-if="!isLoadingHotels">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              search.country ||
                search.state ||
                search.city ||
                search.operador_id ||
                search.marca_asociada_id ||
                search.code ||
                search.hotel_id
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left"
        >
          <v-tooltip
            v-if="!$store.state.app.onlyShow"
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addHotels()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('lbl.addHotels') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow && (model.source.includes('hotetec') || model.source.includes('dingus'))"
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="sendCodes"
          >
            <v-icon>mdi-email-send-outline</v-icon>
            <span class="pl-2">{{ $t('lbl.gestionCodes', { item: model.source.includes('hotetec') ? 'Hotetec' : (model.source.includes('dingus') ? 'Dingus' : '') }) }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          {{ hotels.length }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.hotels').toLowerCase() }}
        </v-col>
      </v-row>
    </template>
    <template v-if="isLoadingInfoPend">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>

    <template v-if="totalInfoPendiente > 0">
      <v-divider class="mb-4 mt-4"></v-divider>
      <v-row>
        <v-col
          v-if="totalInfoPendiente > 0"
          cols="12"
          md="4"
          class="text-center text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="showInfoPendient ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!showInfoPendient ? 'error' : ''"
            @click="infoPendient"
          >
            {{ totalInfoPendiente }} {{ $t('lbl.infoPendiente') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalSinPrice > 0"
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="filters.sin_price ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.sin_price ? 'error' : ''"
            @click="
              filters.sin_price = !filters.sin_price
              changeTags()
            "
          >
            {{ totalSinPrice }} {{ $t('lbl.sinPrice') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalVence > 0"
          cols="12"
          md="4"
          class="text-center text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="showVence ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!showVence ? 'error' : ''"
            @click="setVence"
          >
            {{ totalVence }} {{ $t('lbl.vencimiento') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalSinContact > 0"
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="filters.sin_contact ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.sin_contact ? 'error' : ''"
            @click="
              filters.sin_contact = !filters.sin_contact
              changeTags()
            "
          >
            {{ totalSinContact }} {{ $t('lbl.sinContact') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showInfoPendient">
        <v-col
          cols="12"
          md="12"
          class="text-left text-lowercase"
        >
          <v-btn
            v-if="totalSinRooms > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="filters.sin_rooms ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.sin_rooms ? 'error' : ''"
            @click="
              filters.sin_rooms = !filters.sin_rooms
              changeTags()
            "
          >
            {{ totalSinRooms }} {{ $t('lbl.sinRooms') }}
          </v-btn>

          <v-btn
            v-if="totalSinOcupation > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="filters.sin_ocupation ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.sin_ocupation ? 'error' : ''"
            @click="
              filters.sin_ocupation = !filters.sin_ocupation
              changeTags()
            "
          >
            {{ totalSinOcupation }} {{ $t('lbl.sinOcupation') }}
          </v-btn>

          <v-btn
            v-if="totalSinPlan > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="filters.sin_plan ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.sin_plan ? 'error' : ''"
            @click="
              filters.sin_plan = !filters.sin_plan
              changeTags()
            "
          >
            {{ totalSinPlan }} {{ $t('lbl.sinPlan') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showVence">
        <v-col
          cols="12"
          md="12"
          class="text-left text-lowercase"
        >
          <v-btn
            v-if="totalVence3 > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="filters.vence_3 ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.vence_3 ? 'error' : ''"
            @click="
              filters.vence_3 = !filters.vence_3
              changeTags()
            "
          >
            {{ totalVence3 }} {{ $t('lbl.vencimiento3') }}
          </v-btn>
          <v-btn
            v-if="totalVence6 > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="filters.vence_6 ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!filters.vence_6 ? 'error' : ''"
            @click="
              filters.vence_6 = !filters.vence_6
              changeTags()
            "
          >
            {{ totalVence6 }} {{ $t('lbl.vencimiento6') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-divider class="mb-4 mt-4"></v-divider>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <template v-if="!isLoadingHotels">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-uppercase"
                    width="40%"
                  >
                    {{ $t('lbl.name') }}
                  </th>
                  <!--<th
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    class="text-uppercase"
                    width="5%"
                  >
                    Code
                  </th>-->
                  <th
                    class="text-uppercase"
                    width="5%"
                  >
                    Code
                  </th>
                  <th class="text-uppercase">
                    {{ $t('lbl.destino') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('menu.typeDestination') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('menu.operador') }}
                  </th>
                  <th
                    v-if="permisos.includes('hotel:edit') || permisos.includes('hotel:list')"
                    width="150px"
                    class="text-uppercase"
                    style="text-align: center;"
                  >
                    {{ $t('lbl.actions') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <fragment
                  v-for="(iten, indP) in hotelsPagination"
                  :key="indP"
                >
                  <tr v-if="iten.id === iten.id">
                    <td>
                      <NameHotelStar :hotel="iten" />
                    </td>
                    <!--<td v-if="model.source.includes('hotetec') || model.source.includes('dingus')">
                      {{ iten.code_hotel_externo }}
                    </td>-->
                    <td>{{ iten.code_hotel_externo }}</td>
                    <td>
                      {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                      <span v-if="iten.country">{{ iten.country }}</span>
                    </td>
                    <td>
                      <span v-if="iten.tipo_destino_id">
                        {{ iten.tipo_destino.name }}
                      </span>
                    </td>
                    <td>
                      <span v-if="iten.operador_id">
                        {{ iten.operador.name }}
                      </span>
                      <!--<span v-if="iten.marca_asociada_id">
                        <br />
                        {{ iten.marca_asociada.name }}
                      </span>-->
                    </td>
                    <td
                      v-if="
                        permisos.includes('contrate_hotels:edit') ||
                          permisos.includes('contrate_hotels:list') ||
                          permisos.includes('contrate_hotels:destroy')
                      "
                      style="text-align: center;"
                    >
                      <v-menu
                        bottom
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:edit') && !$store.state.app.onlyShow"
                            link
                            @click="editItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:list') || permisos.includes('*')"
                            link
                            @click="showItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiEye }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.see') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:destroy') && !$store.state.app.onlyShow"
                            link
                            @click="deleteItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </fragment>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text class="pt-2">
            <v-row>
              <v-col
                lg="12"
                cols="12"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  total-visible="5"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="table-tbody"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- MOSTRAR DELETE CONTRATE-->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { item: hotelDelete.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--SEND CODES-->
    <SidebarCodesSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      :name-source="model.source.includes('hotetec') ? 'Hotetec' : (model.source.includes('dingus') ? 'Dingus' : '')"
      :source="model.source.includes('hotetec') ? 'hotetec' : (model.source.includes('dingus') ? 'dingus' : '')"
      :proveedor="proveedor"
      :user="user"
    />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiCheckUnderline,
  mdiMinus,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiCloudUpload,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'
// eslint-disable-next-line import/no-unresolved
import Observation from '@/views/contratos/hotels/utils/Observation.vue'
import SidebarCodesSend from '../utils/SidebarCodesSend.vue'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
    Observation,
    SidebarCodesSend,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingCurrencies: true,
      isLoadingCountries: true,
      isLoadingStates: true,
      isLoadingCities: true,
      isLoadingOperadors: true,
      isLoadingMarcas: true,
      isLoadingHotels: true,
      isLoadingSources: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isLoadingPrices: true,
      isLoadingInfoPend: true,
      showInfoPendient: false,
      showVence: false,
      totalInfoPendiente: 0,
      idsSinRooms: [],
      totalSinRooms: 0,
      idsSinOcupation: [],
      totalSinOcupation: 0,
      idsSinPlan: [],
      totalSinPlan: 0,
      idsSinPrice: [],
      totalSinPrice: 0,
      idsSinContact: [],
      totalSinContact: 0,
      totalVence: 0,
      idsVence3: [],
      totalVence3: 0,
      idsVence6: [],
      totalVence6: 0,
      filters: {
        sin_rooms: false,
        sin_ocupation: false,
        sin_plan: false,
        sin_price: false,
        vence_3: false,
        vence_6: false,
        sin_contact: false,
      },
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiCheckUnderline,
        mdiMinus,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiCloudUpload,
      },
      model: {
        hotels_pactados: [],
        rooms_max: 5,
        paxs_max: 15,
        notificar_acceso_data: false,
        notificar_acceso_disp: false,
        source: [],
      },
      sources: [],
      sourcesAvalibled: [],
      operadoresApiExternHotetec: [],
      operadoresApiExternDingus: [],
      hotelDelete: {
        id: null,
        name: null,
      },
      loading: false,
      loadingSeason: true,
      isUpdatePrices: false,
      pagination: {
        current: 1,
        total: 0,
      },

      hotels: [],
      search: {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      },
      countries: [],
      states: [],
      cities: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,
      itemsHotel: [],
      codesHotel: [],
      allCodes: [],
      allHotel: [],
      searchHotel: null,
      searchCodesHotel: null,
      totalItems: 0,
      totalF: 0,

      itemsPerPage: 10,
      hotelsNomAll: [],
      roomsNomAll: [],
      regimenNomAll: [],
      itemsRooms: [],
      searchRooms: null,
      seasonsContrate: [],
      hotel_pactado: null,
      room_hotel: null,
      season_hotel: [],
      rooms_hotel: [],
      regimen_hotel: 0,
      cantPaxs: 3,
      currencies: [],
      isDialogVisibleDelete: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      editObservations: false,
      rooms_max: [],
      paxs_max: [],
      isSidebarSendActive: false,
      nameSource: '',
      proveedor: null,
      user: null,
      nameHotetec: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      cantTarifarios: 0,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      temporadasContrateHotel: state => state.app.temporadasContrateHotel,
      hotelsAsociadosTemporadas: state => state.app.hotelsAsociadosTemporadas,
      idsHotelsAsociadosTemporadas: state => state.app.idsHotelsAsociadosTemporadas,
      pricesHotelContrate: state => state.app.pricesHotelContrate,
      onlyShow: state => state.app.onlyShow,
      observationsHotel: state => state.app.observationsHotel,
      emailsCodes: state => state.app.emailsCodes,
    }),
    hotelesAll() {
      const hPactados = []
      this.hotelsNomAll.forEach(hote => {
        // this.idsHotelsAsociadosTemporadas.forEach(hAsoc => {
        this.model.hotels_pactados.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            // eslint-disable-next-line camelcase
            let code_hotel_externo = null
            this.model.seasons.forEach(seasonModel => {
              if (seasonModel.hotels.includes(hAsoc)) {
                // eslint-disable-next-line camelcase
                code_hotel_externo = seasonModel.code_hotel_externo
              }
            })
            hPactados.push({
              ...hote,
              code_hotel_externo,
            })
          }
        })
      })

      return hPactados
    },
    hotelsPagination() {
      const arr = []
      let index = 0
      if (this.pagination.current > 1) {
        index = parseInt(this.itemsPerPage * (this.pagination.current - 1), 10)
      }
      const cant = parseInt(index + this.itemsPerPage, 10)

      // eslint-disable-next-line no-plusplus
      for (index; index < cant && index < this.hotels.length; index++) {
        arr.push(this.hotels[index])
      }

      return arr
    },
    verifyContrate() {
      let disabled = true
      const jsonVerify = sessionStorage.getItem('verify-contrate')
      const jsonContrate = JSON.stringify({
        identificador: this.model.identificador,
        cancelado: !!this.model.cancelado,
        currency_id: this.model.currency_id,
        observations: this.observationsHotel,
      })

      if (jsonVerify !== jsonContrate) {
        disabled = false
      }

      return disabled
    },
  },
  watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      }
    },
    searchCodesHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterCodesHotel(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.profile()
    this.getHoteles()
    this.getOperadores()
    this.getCurrencies()
    if (sessionStorage.getItem('contrate-hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    let i = 1
    while (i <= 30) {
      this.rooms_max.push(i)
      // eslint-disable-next-line no-plusplus
      i++
    }

    let j = 1
    while (i <= 30) {
      this.paxs_max.push(j)
      // eslint-disable-next-line no-plusplus
      j++
    }
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'updateHotelesPactadosContrateHotel',
      'addTemporadaContrateHotel',
      'updateIdsHotelsContrateHotel',
      'updateTemporadaContrateHotel',
      'updateCantPaxsContrate',
      'updatePricesHotelContrate',
      'addPricesHotelContrate',
      'updateSeasonContrateHotel',
      'updateRoomSeasonContrateHotel',
      'updateSuplementSeasonContrateHotel',
      'updateRangeAgeContrateHotel',
      'updateObservationContrateHotel',
      'addObservationContrateHotel',
      'addEmailCode',
      'emptyEmailCode',
    ]),
    deleteTarifarios() {
      this.$router.push({ name: 'hotels-contrate-hotels-delete-tarifarios' })
    },
    addTarifa() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-tarifa' })
    },
    addTarifaDirect() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-tarifa-direct' })
    },
    infoPendient() {
      this.showInfoPendient = !this.showInfoPendient
    },
    setVence() {
      this.showVence = !this.showVence
    },
    changeTags() {
      this.hotels = this.hotelesAll

      // FILTRAR
      // SIN ROOMS
      const hotelsSinRooms = []
      this.hotels.forEach(element => {
        if (this.filters.sin_rooms) {
          if (this.idsSinRooms.includes(element.id)) {
            hotelsSinRooms.push(element)
          }
        } else {
          hotelsSinRooms.push(element)
        }
      })
      this.hotels = hotelsSinRooms

      // SIN OCUPATIONS
      const hotelsSinOcupation = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_ocupation) {
          if (this.idsSinOcupation.includes(element.id)) {
            hotelsSinOcupation.push(element)
          }
        } else {
          hotelsSinOcupation.push(element)
        }
      })
      this.hotels = hotelsSinOcupation

      // SIN PLAN
      const hotelsSinPlan = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_plan) {
          if (this.idsSinPlan.includes(element.id)) {
            hotelsSinPlan.push(element)
          }
        } else {
          hotelsSinPlan.push(element)
        }
      })
      this.hotels = hotelsSinPlan

      // SIN PRICE
      const hotelsSinPrice = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_price) {
          if (this.idsSinPrice.includes(element.id)) {
            hotelsSinPrice.push(element)
          }
        } else {
          hotelsSinPrice.push(element)
        }
      })
      this.hotels = hotelsSinPrice

      // SIN CONTACT
      const hotelsSinContact = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_contact) {
          if (this.idsSinContact.includes(element.id)) {
            hotelsSinContact.push(element)
          }
        } else {
          hotelsSinContact.push(element)
        }
      })
      this.hotels = hotelsSinContact

      // VENCE 3
      const hotelsVence3 = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.vence_3) {
          if (this.idsVence3.includes(element.id)) {
            hotelsVence3.push(element)
          }
        } else {
          hotelsVence3.push(element)
        }
      })
      this.hotels = hotelsVence3

      // VENCE 6
      const hotelsVence6 = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.vence_6) {
          if (this.idsVence6.includes(element.id)) {
            hotelsVence6.push(element)
          }
        } else {
          hotelsVence6.push(element)
        }
      })
      this.hotels = hotelsVence6

      this.recalculeTotalPagination()
    },
    getCantPend() {
      const json = {
        contrate_id: sessionStorage.getItem('hotels-contrate-id'),
        search: this.filters,
      }
      this.axios
        .post('contrate_hotels/cant-pending-season', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === true) {
            this.totalInfoPendiente = response.data.data.totalInfoPend
            this.totalSinRooms = response.data.data.totalSinRooms
            this.idsSinRooms = response.data.data.idsSinRooms
            this.totalSinOcupation = response.data.data.totalSinOcupation
            this.idsSinOcupation = response.data.data.idsSinOcupation
            this.totalSinPlan = response.data.data.totalSinPlan
            this.idsSinPlan = response.data.data.idsSinPlan
            this.totalSinPrice = response.data.data.totalSinPrice
            this.idsSinPrice = response.data.data.idsSinPrice
            this.totalSinContact = response.data.data.totalSinContact
            this.idsSinContact = response.data.data.idsSinContact
            this.totalVence = response.data.data.totalVence
            this.totalVence3 = response.data.data.totalVence3
            this.idsVence3 = response.data.data.idsVence3
            this.totalVence6 = response.data.data.totalVence6
            this.idsVence6 = response.data.data.idsVence6
          }
        })
        .finally(() => {
          this.isLoadingInfoPend = false
        })
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
    },
    changeProveedor() {
      this.sourcesAvalibled = []
      if (this.model.proveedor_id) {
        if (this.operadoresApiExternHotetec.filter(e => e === this.model.proveedor_id).length > 0) {
          this.sources.forEach(element => {
            if (element.slug === 'hotetec') {
              this.sourcesAvalibled.push(element)
            }
          })
        }
        if (this.operadoresApiExternDingus.filter(e => e === this.model.proveedor_id).length > 0) {
          this.sources.forEach(element => {
            if (element.slug === 'dingus') {
              this.sourcesAvalibled.push(element)
            }
          })
        }
      } else {
        this.sourcesAvalibled = []
      }
    },
    getSources() {
      this.sources.push({
        name: 'Directo',
        slug: 'directo',
      })
      this.axios
        .post(
          'api_keys/verify-status',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.status) {
            this.nameSource = res.data.data.api_name
            this.nameHotetec = res.data.data.api_name
            this.sources.push({
              name: res.data.data.api_name,
              slug: 'hotetec',
            })
          }
        })
        .finally(() => {
          this.isLoadingSources = false
          this.getApiExtern()
        })
    },
    getApiExtern() {
      this.axios
        .post(
          'api_extern/show',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            if (res.data.data.proveedores) {
              this.operadoresApiExternHotetec = res.data.data.proveedores
            }
          }
        })
        .finally(() => {
          this.axios
            .post(
              'api_extern/show',
              { slug: 'dingusapi' },
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              if (res.data.success) {
                if (res.data.data.proveedores) {
                  this.operadoresApiExternDingus = res.data.data.proveedores
                }
              }
            })
        })
    },
    checkPax(elEvento, model) {
      const numeros = '0123456789'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // NO CUMPLE CON LOS NUMEROS
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }
    },
    addHotels() {
      this.$router.push({ name: 'hotels-contrate-hotels-asociar' })
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
        .finally(() => {
          this.isLoadingCurrencies = false
        })
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },
    filterCodesHotel() {
      this.codesHotel = this.allCodes.filter(e => e.code.toLowerCase())
    },

    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'marca_asociada_id',
          'categoria_id',
          'tipo_destino_id',
          'sugerido',
          'number_sugerido',
          'checkIn',
          'checkOut',
          'direccion',
          'publicado',
          'cant_departament',
        ],
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
          this.getCountries()
          this.getItem()
        })
    },
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.cantTarifarios = res.data.data.cantTarifarios
            this.changeProveedor()
            if (this.model.rooms_max === 0) {
              this.model.rooms_max = 5
            }
            this.emptyEmailCode()
            if (this.model.proveedor_id) {
              this.proveedor = this.model.proveedor
              if (this.proveedor.contacts) {
                if (this.proveedor.contacts.length > 0) {
                  this.proveedor.contacts.forEach(ccc => {
                    ccc.emails.forEach(cccEma => {
                      this.addEmailCode(cccEma.email)
                    })
                  })
                }
              }
              if (this.emailsCodes.length === 0) {
                this.addEmailCode(null)
              }
            }

            this.hotels = this.hotelesAll
            this.allHotel = this.hotels
            this.allHotel.forEach(elementH => {
              this.allCodes.push({
                code: elementH.code_hotel_externo,
              })
            })
            this.totalItems = this.model.hotels_pactados.length

            // PARA LA PAGINACION
            this.recalculeTotalPagination()

            const jsonVerifyUpdate = {
              identificador: this.model.identificador,
              cancelado: !!this.model.cancelado,
              observations: [],
              currency_id: this.model.currency_id,
            }

            if (this.model.observations) {
              this.updateObservationContrateHotel(this.model.observations)
              jsonVerifyUpdate.observations = this.model.observations
            } else {
              this.updateObservationContrateHotel([])
            }

            sessionStorage.setItem('verify-contrate', JSON.stringify(jsonVerifyUpdate))

            // this.updateHotelesPactadosContrateHotel(this.model.hotels_pactados)
          })
          .finally(() => {
            this.isLoading = false
            this.getSources()
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
        .finally(() => {
          this.getCantPend()
          this.isLoadingOperadors = false
          this.isLoadingMarcas = false
        })
    },
    setOperadores() {
      this.hotels = []
      this.allMarcas = []
      this.allHotel = []
      this.itemsHotel = []
      this.itemsMarcas = []
      if (this.operadores.filter(e => e.id === this.search.operador_id).length > 0) {
        if (this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas
          this.allHotel = this.hotelesAll.filter(e => e.operador_id === this.search.operador_id)
          this.itemsMarcas = this.allMarcas
          this.filtersHotels()
        }
      }

      this.recalculeTotalPagination()
    },
    setMarca() {
      this.hotels = []
      this.allHotel = []
      this.itemsHotel = []
      if (this.allMarcas.filter(e => e.id === this.search.marca_asociada_id).length > 0) {
        this.allHotel = this.hotelesAll.filter(e => e.marca_asociada_id === this.search.marca_asociada_id)
        this.filtersHotels()
      }
    },
    setHotel() {
      this.hotels = []
      this.filtersHotels()
    },
    setCodesHotel() {
      this.hotels = []
      this.filtersHotels()
    },
    getCountries() {
      this.hotelsNomAll.forEach(hotelsNom => {
        if (!this.countries.includes(hotelsNom.country)) {
          if (hotelsNom.country) {
            this.countries.push(hotelsNom.country)
          }
        }
      })
      setTimeout(() => {
        this.isLoadingCountries = false
        this.isLoadingStates = false
        this.isLoadingCities = false
      }, 100)
    },
    changeCountry() {
      this.states = []
      this.search.state = null
      this.search.city = null
      if (this.search.country) {
        const states = this.hotelesAll.filter(e => e.country === this.search.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }
      setTimeout(() => {
        this.isLoadingStates = false
      }, 100)
      this.filtersHotels()
    },
    changeState() {
      this.cities = []
      this.search.city = null
      if (this.search.state) {
        const states = this.hotelesAll.filter(e => e.state === this.search.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
      this.filtersHotels()
    },
    changeCity() {
      setTimeout(() => {
        this.isLoadingCities = false
      }, 100)
      this.filtersHotels()
    },
    filtersHotels() {
      this.pagination.current = 1
      this.hotels = []
      this.hotelesAll.forEach(hotel => {
        let introducir = true
        if (this.search.operador_id) {
          if (this.search.operador_id !== hotel.operador_id) {
            introducir = false
          }
        }
        if (this.search.marca_asociada_id) {
          if (this.search.marca_asociada_id !== hotel.marca_asociada_id) {
            introducir = false
          }
        }
        if (this.search.hotel_id) {
          if (this.search.hotel_id !== hotel.id) {
            introducir = false
          }
        }
        if (this.search.country) {
          if (this.search.country !== hotel.country) {
            introducir = false
          }
        }
        if (this.search.state) {
          if (this.search.state !== hotel.state) {
            introducir = false
          }
        }
        if (this.search.city) {
          if (this.search.city !== hotel.city) {
            introducir = false
          }
        }
        if (this.search.code) {
          if (this.search.code !== hotel.code_hotel_externo) {
            introducir = false
          }
        }

        if (introducir) {
          this.hotels.push(
            hotel,

            /* {
            id: hotel.id,
            name: hotel.nameComodin,
          } */
          )
        }
      })

      this.recalculeTotalPagination()
    },
    recalculeTotalPagination() {
      // PARA LA PAGINACION
      this.pagination.total = parseInt(Math.ceil(this.hotels.length / this.itemsPerPage), 10)
    },
    clearSearch() {
      this.pagination.current = 1
      this.hotels = this.hotelesAll
      this.allHotel = this.hotels
      this.states = []
      this.cities = []
      this.search = {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      }

      this.recalculeTotalPagination()
    },

    showItem(iten) {
      sessionStorage.setItem('hotel-contrato-show', true)
      sessionStorage.setItem('hotels-id', iten.id)

      /* this.updateSeasonContrateHotel([])
      this.updateRoomSeasonContrateHotel([])
      this.updateSuplementSeasonContrateHotel([])
      this.updateRangeAgeContrateHotel({
        adults_min_years: null,
        childs_max_years: null,
        infant_max_years: null,
      }) */

      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    editItem(iten) {
      sessionStorage.removeItem('hotel-contrato-show')
      sessionStorage.setItem('hotels-id', iten.id)
      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.hotelDelete.id = iten.id
      this.hotelDelete.name = iten.name
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .post(
          `contrate_hotels/delete-hotel/${sessionStorage.getItem('hotels-contrate-id')}`,
          { hotel_id: this.hotelDelete.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.getItem()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.hotelDelete = {
        id: null,
        name: null,
      }
      this.loading = false
    },

    save() {
      const json = {
        identificador: this.model.identificador,
        publicado: !this.model.stopSale,
        cancelado: this.model.cancelado,
        rooms_max: this.model.rooms_max,
        paxs_max: this.model.paxs_max,
        currency_id: this.model.currency_id,
        source: this.model.source,
        notificar_acceso_data: this.model.notificar_acceso_data,
        notificar_acceso_disp: this.model.notificar_acceso_disp,
        observations: this.observationsHotel,
      }
      this.loading = true

      this.axios
        .put(`contrate_hotels/${this.model.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            // sessionStorage.removeItem('hotels-contrate-id')
            // this.$router.push({ name: 'hotels-contrate-list' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getItem()
          this.loading = false
          this.editObservations = false
        })
    },
    setCanceled() {
      if (this.model.cancelado) {
        this.model.publicado = false
        this.model.stopSale = true
      }
    },

    sendCodes() {
      this.isSidebarSendActive = true
    },
    uploadLote() {
      sessionStorage.setItem('contrate-hotels-id', sessionStorage.getItem('hotels-contrate-id'))
      this.$router.push({ name: 'contrate-hotels-import-files' })
    },
    uploadLoteDingus() {
      sessionStorage.setItem('contrate-hotels-id', sessionStorage.getItem('hotels-contrate-id'))
      this.$router.push({ name: 'contrate-hotels-import-files-dingus' })
    },
  },
}
</script>
<style scoped>
::v-deep .v-messages__message {
  color: red !important;
  margin-top: 10px !important;
}
</style>
